<template>
  <div>
    <v-card elevation="2">
      <v-stepper v-model="e6" vertical>
        <v-stepper-step :complete="e6 > 1" step="1">
          Buyer Information / Información del Comprador
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card class="mb-5" outlined>
            <v-col cols="12">
              <v-form v-model="valid">
                <v-container fluid>
                  <v-row>
                    <v-col cols="6">
                      <v-col cols="12">
                        <v-text-field
                          label="Legal Names / Nombre(s) Legal(es):"
                          v-model="buyerInformation.legalNames"
                        ></v-text-field>
                      </v-col>
                    </v-col>

                    <v-col cols="6">
                      <v-col cols="12">
                        <v-text-field
                          label="Address / Dirección:"
                          v-model="buyerInformation.address"
                        ></v-text-field>
                      </v-col>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4">
                      <v-col cols="12">
                        <v-text-field
                          label="Zip code / C.P:"
                          v-model="buyerInformation.zipCode"
                        ></v-text-field>
                      </v-col>
                    </v-col>

                    <v-col cols="4">
                      <v-col cols="12">
                        <v-text-field
                          label="City / Ciudad:"
                          v-model="buyerInformation.city"
                        ></v-text-field>
                      </v-col>
                    </v-col>

                    <v-col cols="4">
                      <v-col cols="12">
                        <v-text-field
                          label="State / Estado:"
                          v-model="buyerInformation.state"
                        ></v-text-field>
                      </v-col>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4">
                      <v-col cols="12">
                        <v-text-field
                          label="Birthdate / Fecha de Nacimiento:"
                          v-model="buyerInformation.birthdate"
                        ></v-text-field>
                      </v-col>
                    </v-col>

                    <v-col cols="4">
                      <v-col cols="12">
                        <v-text-field
                          label="Nationality / Nacionalidad:"
                          v-model="buyerInformation.nationality"
                        ></v-text-field>
                      </v-col>
                    </v-col>

                    <v-col cols="4">
                      <v-col cols="12">
                        <v-text-field
                          label="Place of birh / Lugar de Nacimiento:"
                          v-model="buyerInformation.placeOfBirth"
                        ></v-text-field>
                      </v-col>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4">
                      <v-col cols="12">
                        <v-text-field
                          label="Occupation / Ocupación:"
                          v-model="buyerInformation.occupation"
                        ></v-text-field>
                      </v-col>
                    </v-col>

                    <v-col cols="4">
                      <v-col cols="12">
                        <v-text-field
                          label="RFC:"
                          v-model="buyerInformation.rfc"
                        ></v-text-field>
                      </v-col>
                    </v-col>

                    <v-col cols="4">
                      <v-col cols="12">
                        <v-text-field
                          label="Marital Status / Estado Civil:"
                          v-model="buyerInformation.maritalStatus"
                        ></v-text-field>
                      </v-col>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4">
                      <v-col cols="12">
                        <v-text-field
                          label="CURP:"
                          v-model="buyerInformation.curp"
                        ></v-text-field>
                      </v-col>
                    </v-col>

                    <v-col cols="4">
                      <v-col cols="12">
                        <v-text-field
                          label="Phone / Teléfono:"
                          v-model="buyerInformation.phone"
                        ></v-text-field>
                      </v-col>
                    </v-col>

                    <v-col cols="4">
                      <v-col cols="12">
                        <v-text-field
                          label="Email:"
                          v-model="buyerInformation.email"
                        ></v-text-field>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-card>

          <v-btn color="primary" @click="e6 = 2">
            Siguiente
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 2" step="2">
          Seller Information / Información del Vendedor
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card class="mb-5" outlined>
            <v-col cols="12">
              <v-form v-model="valid">
                <v-container fluid>
                  <v-row>
                    <v-col cols="6">
                      <v-col cols="12">
                        <v-text-field
                          label="Legal Names / Nombre(s) Legal(es):"
                          v-model="sellerInformation.legalNames"
                        ></v-text-field>
                      </v-col>
                    </v-col>

                    <v-col cols="6">
                      <v-col cols="12">
                        <v-text-field
                          label="Address / Dirección:"
                          v-model="sellerInformation.address"
                        ></v-text-field>
                      </v-col>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6">
                      <v-col cols="12">
                        <v-text-field
                          label="Seller Name / Nombre del Vendedor :"
                          v-model="sellerInformation.sellerNames"
                        ></v-text-field>
                      </v-col>
                    </v-col>

                    <v-col cols="6">
                      <v-col cols="12">
                        <v-text-field
                          label="Advisor / Asesor :"
                          v-model="sellerInformation.Advisor"
                        ></v-text-field>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-card>
          <v-btn text @click="e6 = 1">
            Atras
          </v-btn>
          <v-btn color="primary" @click="e6 = 3">
            Siguiente
          </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 3" step="3">
          Property / Propiedad
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-card class="mb-5" outlined>
            <v-col cols="12">
              <v-form v-model="valid">
                <v-container fluid>
                  <v-row>
                    <v-col cols="6">
                      <v-col cols="12">
                        <v-text-field
                          label="Description of property / Descripción de la propiedad :"
                          v-model="property.description"
                        ></v-text-field>
                      </v-col>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6">
                      <v-col cols="12">
                        <v-text-field
                          label="Name of property / Nombre de la Propiedad :"
                          v-model="property.name"
                        ></v-text-field>
                      </v-col>
                    </v-col>

                    <v-col cols="6">
                      <v-col cols="12">
                        <v-text-field
                          label="Sq. Mts. / Metros Cuadrados (m ) : "
                          v-model="property.sqmts"
                        ></v-text-field>
                      </v-col>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6">
                      <v-col cols="12">
                        <v-text-field
                          label="Price / Precio : "
                          v-model="property.price"
                        ></v-text-field>
                      </v-col>
                    </v-col>

                    <v-col cols="6">
                      <v-col cols="12">
                        <v-text-field
                          label="Price List / Precio de Lista: "
                          v-model="property.priceList"
                        ></v-text-field>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-card>
          <v-btn text @click="e6 = 2">
            Atras
          </v-btn>
          <v-btn color="primary" @click="e6 = 4">
            Siguiente
          </v-btn>
        </v-stepper-content>

        <v-stepper-step step="4">
          Offer / Oferta
        </v-stepper-step>
        <v-stepper-content step="4">
          <v-card class="mb-5" outlined>
            <v-col cols="12">
              <v-form v-model="valid">
                <v-container fluid>
                  <v-row>
                    <v-col cols="6">
                      <v-col cols="12">
                        <v-text-field
                          label="Offer / Oferta :"
                          v-model="offer.offer"
                        ></v-text-field>
                      </v-col>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6">
                      <v-col cols="12">
                        <v-text-field
                          label="Payment Method / Forma de Pago :"
                          v-model="offer.paymentMethod"
                        ></v-text-field>
                      </v-col>
                    </v-col>

                    <v-col cols="6">
                      <v-col cols="12">
                        <v-text-field
                          label="Down Payment / Apartado: "
                          v-model="offer.downPayment"
                        ></v-text-field>
                      </v-col>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <v-col cols="12">
                        <v-text-field
                          label="Payment Plan / Plan de Pago : "
                          v-model="offer.paymentPlan"
                        ></v-text-field>
                      </v-col>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-card>
          <v-btn text @click="e6 = 3">
            Atras
          </v-btn>
          <v-btn color="primary" @click="generatePDF">
            Generar PDF
          </v-btn>
        </v-stepper-content>
      </v-stepper>
    </v-card>
    <br />
    <!--   <v-flex text-right>
      <v-btn @click="generatePDF">Generar</v-btn>
    </v-flex> -->
  </div>
</template>
<script>
import printPromisePDF from "@/pdf/promise-pdf-generate.js";
export default {
  name: "PromisePDF",
  data: () => ({
    e6: 1,
    valid: false,
    buyerInformation: {
      legalNames: "Nombre",
      address: "Dirección",
      zipCode: "C.P",
      city: "Ciudad",
      state: "Estado",
      birthdate: "Fecha de nacimiento",
      nationality: "Nacionalidad",
      placeOfBirth: "Lugar de Nacimiento",
      occupation: "Ocupación",
      rfc: "RFC",
      maritalStatus: "Estado civil",
      curp: "CURP",
      phone: "Telefono",
      email: "Email"
    },
    sellerInformation: {
      legalNames: "Nombre",
      address: "Dirección",
      sellerNames: "Nombre del vendedor",
      Advisor: "Asesor"
    },
    property: {
      description: "Descripción de la propiedad",
      //Pendiente
      name: "Nombre de la propiedad",
      sqmts: "ddd",
      price: "1000",
      priceList: "10000"
    },
    offer: {
      offer: "Oferta",
      //Pendiente
      paymentMethod: "Forma de Pago",
      downPayment: "Apartado",
      paymentPlan: "Plan de pago"
    }
  }),
  methods: {
    generatePDF() {
      if (
        this.buyerInformation &&
        this.sellerInformation &&
        this.property &&
        this.offer
      ) {
        printPromisePDF.create(
          this.buyerInformation,
          this.sellerInformation,
          this.property,
          this.offer
        );
      }
    }
  }
};
</script>
