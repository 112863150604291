import { jsPDF } from "jspdf";
import "jspdf-autotable";
import LatoBoldnormal from "@/pdf/fonts/LatoBoldnormal";
LatoBoldnormal();

let doc;
let row = 0;

function create(byrInfo, sllerInfo, prty, ofr) {
  //Configuración
  doc = jsPDF("p", "mm", [220, 280]);
  doc.setFont("Lato-Bold", "normal");
  doc.setTextColor("262D3C");
  doc.setFontSize(9);

  buyerInformation(byrInfo);
  sellerInformation(sllerInfo);
  property(prty);
  offer(ofr);

  window.open(doc.output("bloburl"));
}

//head

function buyerInformation(byrInfo) {
  sectionTitle(60, "BUYER INFORMATION / INFORMACIÓN DEL COMPRADOR");

  vrow(() => {
    vsubheader(
      "start",
      "end",
      "Legal Names / Nombre(s) Legal (es) :",
      byrInfo.legalNames
    );
  });

  vrow(() => {
    vsubheader("start", "end", "Address / Dirección :", byrInfo.address);
  });

  vrow(() => {
    let xtemp;

    xtemp = vsubheader("start", 35, "Zip code / C.P. :", byrInfo.zipCode);

    xtemp = vsubheader(xtemp + 3, 35, "City / Ciudad : ", byrInfo.city);

    vsubheader(xtemp + 3, "end", "State / Estado :", byrInfo.state);
  });

  vrow(() => {
    vsubheader(
      "start",
      "end",
      "Birthdate / Fecha de Nacimiento :",
      byrInfo.birthdate
    );
  });

  vrow(() => {
    let xtemp;

    xtemp = vsubheader(
      "start",
      40,
      "Nationality / Nacionalidad :",
      byrInfo.nationality
    );

    vsubheader(
      xtemp + 3,
      "end",
      "Place of birh / Lugar de Nacimiento :",
      byrInfo.placeOfBirth
    );
  });

  vrow(() => {
    let xtemp;

    xtemp = vsubheader(
      "start",
      60,
      "Occupation / Ocupación : ",
      byrInfo.occupation
    );

    vsubheader(xtemp + 3, "end", "RFC : ", byrInfo.rfc);
  });

  vrow(() => {
    let xtemp;

    xtemp = vsubheader(
      "start",
      60,
      "Marital Status / Estado Civil :",
      byrInfo.maritalStatus
    );

    vsubheader(xtemp + 3, "end", "CURP :  ", byrInfo.curp);
  });

  vrow(() => {
    let xtemp;

    xtemp = vsubheader("start", 60, "Phone / Teléfono : ", byrInfo.phone);

    vsubheader(xtemp + 3, "end", "Email : ", byrInfo.email);
  });
}

function sellerInformation(sllerInfo) {
  sectionTitle(row + 10, "SELLER INFORMATION / INFORMACIÓN DEL VENDEDOR");

  vrow(() => {
    vsubheader(
      "start",
      "end",
      "Legal Names / Nombre(s) Legal (es) : ",
      sllerInfo.legalNames
    );
  });

  vrow(() => {
    vsubheader("start", "end", "Address / Dirección :", sllerInfo.address);
  });

  vrow(() => {
    vsubheader(
      "start",
      "end",
      "Seller Name / Nombre del Vendedor :   ",
      sllerInfo.sellerNames
    );
  });

  vrow(() => {
    vsubheader("start", "end", "Advisor / Asesor : ", sllerInfo.Advisor);
  });
}

function property(prty) {
  sectionTitle(row + 10, "PROPERTY / PROPIEDAD");

  vrow(() => {
    vsubheader(
      "start",
      "end",
      "Description of property / Descripción de la propiedad : ",
      prty.description
    );
  });

  vrow(() => {
    vsubheader(
      "start",
      "end",
      "Name of property / Nombre de la Propiedad :   ",
      prty.name
    );
  });

  vrow(() => {
    let xtemp;

    xtemp = vsubheader(
      "start",
      45,
      "Sq. Mts. / Metros Cuadrados (m ) :",
      prty.sqmts
    );

    vsubheader(xtemp + 3, "end", "Price / Precio :", prty.price);
  });

  vrow(() => {
    vsubheader("start", "end", "Price List / Precio de Lista:", prty.priceList);
  });
}

function offer(ofr) {
  sectionTitle(row + 10, "OFFER / OFFERTA");
  vrow(() => {
    vsubheader("start", 45, "Offer / Oferta : ", ofr.offer);
  });

  vrow(() => {
    let xtemp;

    xtemp = vsubheader(
      "start",
      45,
      "Payment Method / Forma de Pago :   ",
      ofr.paymentMethod
    );

    vsubheader(
      xtemp + 3,
      "end",
      " Down Payment / Apartado:   ",
      ofr.downPayment
    );
  });

  vrow(() => {
    vsubheader(
      "start",
      "end",
      "Payment Plan / Plan de Pago : ",
      ofr.paymentPlan
    );
  });
}
//Funciones que se reutizan

function vsubheader(x, sizeField, label, value) {
  if (x === "start") {
    x = 15;
    doc.text(label, x, row);
  } else {
    doc.text(label, x, row);
  }

  doc.setDrawColor("7D8491");
  doc.setLineWidth(0.4);

  const xField = label.length * 1.45 + x;

  doc.text(value, xField + 2, row);

  if (sizeField === "end") {
    doc.line(xField, row + 1, 205, row + 1);
  } else {
    doc.line(xField, row + 1, xField + sizeField, row + 1);
    return xField + sizeField;
  }
}
function vrow(callback) {
  row += 6;
  callback();
}
function sectionTitle(vtop, vcontent) {
  doc.autoTable({
    startY: vtop,

    body: [
      [
        {
          content: vcontent,
          styles: {
            halign: "center",
            fontSize: 9,
            cellPadding: 1,
            //font: "Lato-Bold",
            //textColor: color.title_color
            textColor: [255, 255, 255],
            font: "Lato-Bold",
            fillColor: "7D8491"
          }
        }
      ]
    ]
  });
  row = vtop + 7;
}
export default {
  create
};
